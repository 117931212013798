import React from "react";
import "./Logo.css";
import Badge from "./Badge";

function Logo() {
  return (
    <div className="complete-logo">
      <div className="hexagon-container">
        <Badge />
      </div>
      <div className="arta">RTA</div>
    </div>
  );
}

export default Logo;
