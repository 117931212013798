import React from "react";
import "./Badge.css";

function Badge() {
  return (
    <div className="hexagon">
      <div className="logo-letter">A</div>
    </div>
  );
}

export default Badge;
